import React, { useEffect, useState } from 'react'
import vilLogoRed from '../../../../../../assets/images/vi-busi.png'
import vilLogoBlack from '../../../../../../assets/images/logo-bg-black.jpg'
import { Spinner, Center, useTheme } from '@chakra-ui/react'
import { useAuthContext } from '../../../../../../context/auth.context'
import {
	useExcelJsonList,
	useLoadFieldValues,
	useLoadRomsFormFieldListData,
	useLoadAllFormData,
	useLoadCompanySeal,
} from '../../../../../../hooks/form.hooks'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { encode } from '../../../caf-crf-view/cafCrf.util'
import sdWanStyle from './SdwanCafForm.module.css'
import ColoCofTermsConditionsNew from '../../ColoCofTermsConditions'
import sdWancmnStyle from './sdwancaf.module.css'
import SdwanTermsCondtions from './SdwanTermsCondtions'
import { JsonToTable } from 'react-json-to-table'

const SdwanCafForm = ({
	formData,
	queueData,
	romsActivity,
	submittedForms,
}) => {
	const chakraTheme = useTheme()
	let componentRef = React.useRef()
	const [crfData, setcrfData] = useState(false)
	const [sdWanCafDataupdated, setUpdatedcafData] = useState(null)
	const [imageData, setImageData] = useState(null)
	const [imageType, setImageType] = useState(null)
	const [custImageData, setCustImageData] = useState(null)
	const [custImageType, setCustImageType] = useState(null)
	const [acctMngSignImageData, setacctMngSignImageData] = useState(null)
	const [offiStampCustImageData, setOffiStampCustImageData] = useState(null)
	const [signateImageData, setSignateImageData] = useState(null)
	const [desigAuthSignImageData, setDesigAuthSignImageData] = useState(null)
	const [photoImageData, setPhotoImageData] = useState(null)
	const [vodafoneCustImageData, setVodafoneCustImageData] = useState(null)
	const [signateVodaImageData, setSignateVodaImageData] = useState(null)
	const [desigAuthVodaSignImageData, setDesigAuthVodaSignImageData] =
		useState(null)
	const [tableData, setTableData] = useState([])
	const [excelJson, setexcelJson] = useState(null)

	const {
		state: { authData },
	} = useAuthContext()
	let coloCrfContentView = ''

	const { mutate } = useLoadRomsFormFieldListData()
	const { mutate: loadAllData } = useLoadAllFormData()
	const { mutate: loadExcelJsonValues } = useExcelJsonList()
	const { mutate: loadOrderFormValues } = useLoadFieldValues()
	const { mutate: loadCompanySeal } = useLoadCompanySeal()

	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setcrfData(false)
					setUpdatedcafData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}
	const fetchTableData = () => {
		loadAllData(
			{
				workflow_activity_id: queueData?.activity_id,
				form_id: romsActivity?.multi_submission_form_id,
				page_start: 0,
				page_limit: 50,
			},
			{
				onSuccess: async data => {
					if (data.length > 0) {
						let allInlineActivitiesArray = []
						for (const item of data) {
							if (!!item.activity_inline_data)
								allInlineActivitiesArray.push(item.activity_inline_data)
						}
						setTableData(allInlineActivitiesArray)
					}
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}

	const fetchOrderFormValues = () => {
		let orderForm = submittedForms.find(
			item => item.form_id === romsActivity.order_form_id
		)
		loadOrderFormValues(
			{
				form_id: romsActivity?.order_form_id,
				form_transaction_id: !!orderForm
					? orderForm.data_form_transaction_id
					: 0,
			},
			{
				onSuccess: async res => {
					if (res.length > 0) {
						let ExcelIndex = res[0].activity_inline_data.findIndex(
							res1 => res1.field_id === 347187
						)
						let excelUrl
						if (ExcelIndex > -1) {
							excelUrl = res[0].activity_inline_data[ExcelIndex].field_value
						}
						if (!!excelUrl) {
							loadExcelJsonValues(
								{ bucket_url: excelUrl },
								{
									onSuccess: async result => {
										setexcelJson(JSON.parse(result))
									},
								}
							)
						}
					}
				},
			}
		)
	}

	const fetchCompanySeal = () => {
		loadCompanySeal(
			{
				form_id: 56464,
				field_id: 0,
				activity_id: queueData.activity_id,
			},
			{
				onSuccess: async res => {
					let companySeal = ''
					let data = []
					if (res.length > 0) {
						data = JSON.parse(res[0].data_entity_inline).form_submitted
					} else {
						data = []
					}
					data.forEach(function (fieldObj, index) {
						if (Number(fieldObj.field_id) === 346960) {
							companySeal = fieldObj.field_value
							loadImageBlob(companySeal, (data, type) => {
								setImageData(data)
								setImageType(type)
							})
						}
					})
				},
			}
		)
	}

	useEffect(() => {
		setcrfData(true)
		fetchFieldListData()
		fetchTableData()
		fetchOrderFormValues()
		fetchCompanySeal()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		let data = sdWanCafDataupdated
		if (!!data) {
			// if (!!data[347123]?.value) {
			// 	// customer company seal
			// 	let custCompanySeal = data[347123]?.value

			// 	loadImageBlob(custCompanySeal, (data, type) => {
			// 		setImageData(data)
			// 		setImageType(type)
			// 	})
			// }
			if (!!data[347120]?.value) {
				// customer  sign
				let custCompanySeal = data[347120]?.value
				loadImageBlob(custCompanySeal, (data, type) => {
					setCustImageData(data)
					setCustImageType(type)
				})
			}
			if (!!data[347121]?.value) {
				// manager sign url
				let acctManagerSignUrl = data[347121]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setacctMngSignImageData(data)
				})
			}
			if (!!data[347123]?.value) {
				// Official of Stamp of Customer
				let acctManagerSignUrl = data[347123]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setOffiStampCustImageData(data)
				})
			}
			if (!!data[347124]?.value) {
				// Signatures
				let acctManagerSignUrl = data[347124]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setSignateImageData(data)
				})
			}
			if (!!data[347125]?.value) {
				// Designation Authorised Signatory
				let acctManagerSignUrl = data[347125]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setDesigAuthSignImageData(data)
				})
			}
			if (!!data[346895]?.value) {
				// self Authorised Signatory
				let acctManagerSignUrl = data[346895]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setPhotoImageData(data)
				})
			}

			if (!!data[347132]?.value) {
				// Official of Stamp of voda
				let acctManagerSignUrl = data[347132]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setVodafoneCustImageData(data)
				})
			}
			if (!!data[347133]?.value) {
				// Signatures
				let acctManagerSignUrl = data[347133]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setSignateVodaImageData(data)
				})
			}
			if (!!data[347134]?.value) {
				// Designation Authorised Signatory voda
				let acctManagerSignUrl = data[347134]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setDesigAuthVodaSignImageData(data)
				})
			}
		}
	}, [sdWanCafDataupdated])
	if (!!sdWanCafDataupdated) {
		let customerSignSplit =
			!!sdWanCafDataupdated[347120]?.value &&
			sdWanCafDataupdated[347120]?.value?.length > 0
				? sdWanCafDataupdated[347120]?.value.split(' ')
				: ''
		let firstCaps
		let secondBold
		let Signcss
		if (customerSignSplit.length > 0) {
			firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase()
			secondBold = customerSignSplit['1']
			Signcss = 'signate_img input_sign'
		} else {
			firstCaps = customerSignSplit
			secondBold = ''
			Signcss = 'signate_img input_sign under_line'
		}

		coloCrfContentView = (
			<div>
				<div
					style={{ textAlign: 'left', marginTop: '5px' }}
					className={sdWanStyle.tab_content2}
					ref={el => (componentRef = el)}
				>
					<page size='A4'>
						<div className={sdWanStyle.section2}>
							<div className={sdWanStyle.font8}>
								<div className={sdWanStyle.over_hid}>
									<div
										className={`${sdWanStyle.width110px} ${sdWanStyle.fleft}`}
									>
										<img src={vilLogoRed} />
									</div>
									<div className={`${sdWanStyle.width50} ${sdWanStyle.fleft}`}>
										<p
											className={`${sdWanStyle.customfom} ${sdWanStyle.margin0} ${sdWanStyle.redcolor} ${sdWanStyle.flright}`}
										>
											<strong
												className={`${sdWanStyle.textcenter} ${sdWanStyle.in_block}`}
											>
												Customer Aquisition Form <br />
												Hybrid SD-WAN
											</strong>
										</p>
									</div>

									<div
										className={`${sdWanStyle.width30} ${sdWanStyle.pad_5t} ${sdWanStyle.flright} `}
									>
										<div className={sdWanStyle.caf}>
											CAF No
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[346887]?.value}
											/>
										</div>
										<div className={sdWanStyle.caf_inner}>
											<strong> Account Code</strong>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.no_top}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[346888]?.value}
											/>
										</div>
									</div>
								</div>

								<div
									className={`${sdWanStyle.over_hid} ${sdWanStyle.top}-${30}`}
								>
									<div className={`${sdWanStyle.width70} ${sdWanStyle.fleft}`}>
										<div class={sdWancmnStyle.padt70}>
											<div
												className={`${sdWanStyle.over_hid} ${sdWanStyle.mar_t}-5`}
											>
												<div className={sdWanStyle.pd_t5}>
													<strong>Company(Customer) Name</strong>
													<span className={sdWanStyle.pad_l10}>
														<input
															className={sdWanStyle.form_col_input}
															type='text'
															name=''
															value={sdWanCafDataupdated[346889]?.value}
														/>
													</span>
												</div>
											</div>
											<p
												className={`${sdWanStyle.sign} ${sdWanStyle.underline}`}
											>
												<strong>
													<u>Authorised Signatory Details</u>
												</strong>
											</p>
										</div>
									</div>

									<div
										className={`${sdWanStyle.width20} ${sdWanStyle.pad_5t} ${sdWanStyle.flright} `}
									>
										{photoImageData !== null && photoImageData !== '' ? (
											<div
												//className='signate_img1 input_sign1'
												className={sdWancmnStyle.cross_fill}
												style={{
													marginBottom: '13px',
													// width: '113px',
													// border: '1px solid #ddd',
												}}
											>
												<img
													src={
														photoImageData !== null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  encode(photoImageData)
															: ''
													}
													style={{
														width: '105px',
														marginLeft: '5px',
													}}
													alt={'Self attested Sign'}
												/>
												<div className={sdWancmnStyle.self_attach}>
													{firstCaps !== '' ? (
														<div
															style={{ padding: '0px', color: 'black' }}
															className={Signcss}
														>
															{firstCaps + '  .  '}
															<span className='under_line'>{secondBold}</span>
														</div>
													) : (
														''
													)}
												</div>
											</div>
										) : (
											<>
												<div
													className={sdWancmnStyle.cross_fill}
													style={{
														marginBottom: '13px',
														textAlign: 'center',
													}}
												>
													<p>Self attested</p>
													<br />
													<p>passport size</p>
													<br />
													<p>colour photograph</p>
													<br />
													<p>of Authorised Signatory</p>
													<br />
												</div>
											</>
										)}
										<div className={sdWancmnStyle.cross_fill}></div>
									</div>
								</div>

								<div className={`${sdWanStyle.over_hid} ${sdWanStyle.auth} `}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width45} `}>
										Name
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width85} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346891]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										Contact No:
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width59} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346892]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width30} `}>
										Email:
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346893]?.value}
										/>
									</div>
								</div>

								<p className={`${sdWanStyle.font9} ${sdWanStyle.margin0} `}>
									I/We hereby order for VIL Hybrid SD-WAN Service subject to
									terms and conditions as described in the service order form.
									We furnish the necessary details as fallow.
								</p>
								<div className={sdWanStyle.pad_t10}>
									<p className={sdWanStyle.ligthbg}>BILLING ADDRESS</p>
								</div>

								<p className={`${sdWanStyle.auth} ${sdWanStyle.margin0} `}>
									Contact Person name
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width60} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[346905]?.value}
									/>
								</p>
								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Designation
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346906]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Dept. Name
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346907]?.value}
										/>
									</div>
								</div>
								<div className={`${sdWanStyle.auth} ${sdWanStyle.margin0} `}>
									<span className={sdWanStyle.width54px}>Address</span>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width90} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[346908]?.value}
									/>
								</div>
								{/* <div className={`${sdWanStyle.auth} ${sdWanStyle.margin0} `}>
									<span className={sdWanStyle.width54px}> </span>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width90} `}
										type='text'
										name=''
										value=''
									/>
								</div> */}
								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width40} `}>
										City/Village/Post office
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width54} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346909]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width20} `}>
										State
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346910]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width20} `}>
										Pincode
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346911]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width20} `}>
										Telephone
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width58} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346912]?.value}
										/>
									</div>
								</div>
								<div className={`${sdWanStyle.auth} ${sdWanStyle.over_hid} `}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width58} `}>
										Landmark
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width84} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346913]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width42} `}>
										<span className={sdWanStyle.faxn}>Fax No.</span>
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width66} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346914]?.value}
										/>
									</div>
								</div>
								<div className={`${sdWanStyle.auth} ${sdWanStyle.over_hid} `}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width58} `}>
										Contact Email Id
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width77} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346915]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width42} `}>
										Mobile No / Alt No.
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width66} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346916]?.value}
										/>
									</div>
								</div>

								<div className={sdWanStyle.pad_t10}>
									<p className={sdWanStyle.ligthbg}>INSTALLATION ADDRESS</p>
								</div>
								<p className={`${sdWanStyle.font9} ${sdWanStyle.margin0} `}>
									I/We hereby order for VIL Hybrid SD-WAN Service subject to
									terms and conditions as described in the service order form.
									We furnish the necessary details as follows This Address is
									for installation
								</p>

								<p className={sdWanStyle.auth}>
									Contact Person name
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width60} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[346938]?.value}
									/>
								</p>
								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Designation
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346939]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Dept. Name
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346940]?.value}
										/>
									</div>
								</div>
								<div className={`${sdWanStyle.auth} ${sdWanStyle.margin0} `}>
									<span className={sdWanStyle.width54px}>Address</span>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width90} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[346941]?.value}
									/>
								</div>
								{/* <div className={`${sdWanStyle.auth} ${sdWanStyle.pad_b5} `}>
									<span className={sdWanStyle.width54px}> </span>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width90} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[346942]?.value}
									/>
								</div> */}
								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										City/Village/Post office
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346942]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										State
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346943]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										Pincode
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346944]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										Telephone
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346945]?.value}
										/>
									</div>
								</div>
								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Landmark
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width84} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346946]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										<span className={sdWanStyle.faxn}>Telecom Circle.</span>
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width50} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346947]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										<span className={sdWanStyle.faxn}>Fax No.</span>
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width50} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346948]?.value}
										/>
									</div>
								</div>
								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width58} `}>
										Contact Email Id
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width77} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346949]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width42} `}>
										Mobile No / Alt No.
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width66} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346950]?.value}
										/>
									</div>
								</div>
								<p>*Mandatory for GST registered companies</p>
								<div
									className={`${sdWanStyle.check} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15}`}>
										GSTIN
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346951]?.value === 'GSTIN'}
											disabled={sdWanCafDataupdated[346951]?.value !== 'GSTIN'}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										UIN
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346951]?.value === 'UIN'}
											disabled={sdWanCafDataupdated[346951]?.value !== 'UIN'}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										GST_ISD
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346951]?.value === 'GST_ISD'}
											disabled={
												sdWanCafDataupdated[346951]?.value !== 'GST_ISD'
											}
										/>
									</div>
								</div>
								<div
									className={`${sdWanStyle.check} ${sdWanStyle.margin0}  ${sdWanStyle.over_hid}  ${sdWanStyle.typecheck} `}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width12}  ${sdWanStyle.pad_t3}  `}
									>
										<strong> GST Customer Type </strong>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width12} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346952]?.value === 'Normal'}
											disabled={sdWanCafDataupdated[346952]?.value !== 'Normal'}
										/>
										Normal
									</div>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width12}   `}
									>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346952]?.value === 'SEZ'}
											disabled={sdWanCafDataupdated[346952]?.value !== 'SEZ'}
										/>
										SEZ
									</div>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width12}   `}
									>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346952]?.value === 'Diploma'}
											disabled={
												sdWanCafDataupdated[346952]?.value !== 'Diploma'
											}
										/>
										Diplomat
									</div>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width12}   `}
									>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346952]?.value === 'Embassy'}
											disabled={
												sdWanCafDataupdated[346952]?.value !== 'Embassy'
											}
										/>
										Embassy
									</div>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width12}   `}
									>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346952]?.value === 'Consulate'
											}
											disabled={
												sdWanCafDataupdated[346952]?.value !== 'Consulate'
											}
										/>
										Consulate
									</div>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width12}   `}
									>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346952]?.value === 'Special Agency'
											}
											disabled={
												sdWanCafDataupdated[346952]?.value !== 'Special Agency'
											}
										/>
										Special Agency
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width12}`}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346952]?.value ===
												'Person/Class of Person'
											}
											disabled={
												sdWanCafDataupdated[346952]?.value !==
												'Person/Class of Person'
											}
										/>
										Person/Class of Person
									</div>
								</div>
								<p className={sdWanStyle.auth}>
									GSTIN / UIN / GST_ISD No
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.wid50}`}
										type='text'
										name=''
										value={sdWanCafDataupdated[346953]?.value}
									/>
								</p>
								<div className={sdWanStyle.auth}>
									<span
										className={`${sdWanStyle.width25} ${sdWanStyle.in_block}`}
									>
										GST Regd. Address in Installation State
									</span>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width60} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[346954]?.value}
									/>
								</div>

								<div
									className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.typecheck} ${sdWanStyle.gstcheck}`}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width15} ${sdWanStyle.pad_t3}`}
									>
										<strong>Customer Type</strong>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346955]?.value === 'IT'}
											disabled={sdWanCafDataupdated[346955]?.value !== 'IT'}
										/>
										IT
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346955]?.value === 'Tech'}
											disabled={sdWanCafDataupdated[346955]?.value !== 'Tech'}
										/>
										Tech
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346955]?.value ===
												'Customer Service'
											}
											disabled={
												sdWanCafDataupdated[346955]?.value !==
												'Customer Service'
											}
										/>
										Customer Service
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346955]?.value === 'Legal'}
											disabled={sdWanCafDataupdated[346955]?.value !== 'Legal'}
										/>
										Legal
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346955]?.value === 'Finance'}
											disabled={
												sdWanCafDataupdated[346955]?.value !== 'Finance'
											}
										/>
										Finance
									</div>
								</div>

								<div
									className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.typecheck} ${sdWanStyle.gstcheck}`}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width15} ${sdWanStyle.pad_t3}`}
									>
										<strong>&nbsp;</strong>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346955]?.value === 'Purchase'
											}
											disabled={
												sdWanCafDataupdated[346955]?.value !== 'Purchase'
											}
										/>
										Purchase
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346955]?.value === 'SCM'}
											disabled={sdWanCafDataupdated[346955]?.value !== 'SCM'}
										/>
										SCM
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346955]?.value === 'Delivery'
											}
											disabled={
												sdWanCafDataupdated[346955]?.value !== 'Delivery'
											}
										/>
										Delivery
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346955]?.value === 'Others'}
											disabled={sdWanCafDataupdated[346955]?.value !== 'Others'}
										/>
										Others
									</div>
								</div>

								<p className={`${sdWanStyle.auth} ${sdWanStyle.namsupp}`}>
									<strong>Name of Supporting Document</strong>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.wid50}`}
										type='text'
										name=''
										value={sdWanCafDataupdated[346956]?.value}
									/>
								</p>
								<p className={sdWanStyle.gsttext}>
									(GST Certificate, SEZ Certificate, Diploma Crtificate)
								</p>
								<div className={sdWanStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className={sdWanStyle.section2}>
							<div className={sdWanStyle.font8}>
								<div className={sdWanStyle.pad_t5}>
									<p className={sdWanStyle.ligthbg}>CUSTOMER TYPE </p>
								</div>
								<div
									class='check over_hid custype'
									style={{ marginTop: '10px' }}
								>
									<div class='fleft width14'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346958]?.value === 'General'}
											disabled={
												sdWanCafDataupdated[346958]?.value !== 'General'
											}
										/>
										General
									</div>
									{/* <div class='fleft width14'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346958]?.value === 'ISP'}
											disabled={sdWanCafDataupdated[346958]?.value !== 'ISP'}
										/>
										ISP
									</div> */}
									{/* <div class='fleft width14'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346958]?.value === 'ISP License No'
											}
											disabled={
												sdWanCafDataupdated[346958]?.value !== 'ISP License No'
											}
										/>
										ISP License No
									</div>
									<div class='fleft width14'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346958]?.value === 'OSP Reg No'
											}
											disabled={
												sdWanCafDataupdated[346958]?.value !== 'OSP Reg No'
											}
										/>
										OSP Reg No
									</div> */}

									<div class='fleft width14'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346958]?.value === 'Telemarketer'
											}
											disabled={
												sdWanCafDataupdated[346958]?.value !== 'Telemarketer'
											}
										/>
										Telemarketer
									</div>
									<div class='fleft width14'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346958]?.value === 'ВРО'}
											disabled={sdWanCafDataupdated[346958]?.value !== 'ВРО'}
										/>
										ВРО
									</div>
									<div class='fleft width14'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346958]?.value === ' Others'}
											disabled={
												sdWanCafDataupdated[346958]?.value !== ' Others'
											}
										/>
										Others
									</div>
								</div>

								{/* <div className={sdWanStyle.pad_t5}>
									<p className={sdWanStyle.ligthbg}>IF ISP LICENSE NO </p>
								</div>

								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										ISP License No
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346964]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										Date
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346965]?.value}
										/>
									</div>
								</div> */}

								{/* <p className={sdWanStyle.ligthbg}>IF OSP REG NO</p>
								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										OSP Reg No
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346967]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										Date
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346968]?.value}
										/>
									</div>
								</div> */}

								<div
									class='check over_hid custype'
									style={{ marginTop: '5px' }}
								>
									<div class='fleft width15_2 pad_7t'>
										Customer Vertical Type
									</div>
									<div class='fleft width15'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'Automotive'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Automotive'
											}
										/>
										Automotive
									</div>
									<div class='fleft width20'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'Consumer Goods'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Consumer Goods'
											}
										/>
										Consumer Goods
									</div>
									<div class='fleft width20'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value ===
												'Diversified & Projects'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !==
												'Diversified & Projects'
											}
										/>
										Diversified & Projects
									</div>
									<div class='fleft width25'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'Education'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Education'
											}
										/>
										Education
									</div>
								</div>
								<div class='check over_hid custype'>
									<div class='fleft width15_2 pad_7t'>&nbsp;</div>
									<div class='fleft width15'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'Engineering'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Engineering'
											}
										/>
										Engineering
									</div>
									<div class='fleft width20'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value ===
												'Industry Associations'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !==
												'Industry Associations'
											}
										/>
										Industry Associations
									</div>
									<div class='fleft width20'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'Health Care'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Health Care'
											}
										/>
										Health Care
									</div>
									<div class='fleft width25'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346969]?.value === 'Finance'}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Finance'
											}
										/>
										Finance
									</div>
								</div>
								<div class='check over_hid custype'>
									<div class='fleft width15_2 pad_7t'>&nbsp;</div>
									<div class='fleft width15  '>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'Government'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Government'
											}
										/>
										Government
									</div>
									<div class='fleft width20'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'IT & Telecom'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'IT & Telecom'
											}
										/>
										IT & Telecom
									</div>
									<div class='fleft width20'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'Logictics'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Logictics'
											}
										/>
										Logictics
									</div>
									<div class='fleft width25'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value ===
												'Manufacturing & Processing'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !==
												'Manufacturing & Processing'
											}
										/>
										Manufacturing & Processing
									</div>
								</div>
								<div class='check over_hid custype'>
									<div class='fleft width15_2 pad_7t'>&nbsp;</div>
									<div class='fleft width15  '>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346969]?.value === 'Trading'}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Trading'
											}
										/>
										Trading
									</div>
									<div class='fleft width20 fnt8'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'Services'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Services'
											}
										/>
										Services
									</div>
									<div class='fleft width20'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346969]?.value === 'Projects EPC'
											}
											disabled={
												sdWanCafDataupdated[346969]?.value !== 'Projects EPC'
											}
										/>
										Projects EPC
									</div>
									<div class='fleft width25'>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346969]?.value === 'Others'}
											disabled={sdWanCafDataupdated[346969]?.value !== 'Others'}
										/>
										Others
									</div>
								</div>

								<div className={sdWanStyle.pad_t5}>
									<p className={sdWanStyle.ligthbg}>SERVICE ORDER DETAILS </p>
								</div>
								<div
									className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype}`}
									style={{ marginTop: '15px' }}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width15_2} ${sdWanStyle.pad_7t}`}
									>
										Type of Order
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346972]?.value === 'New'}
											disabled={sdWanCafDataupdated[346972]?.value !== 'New'}
										/>
										New
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width20} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346972]?.value === 'POC'}
											disabled={sdWanCafDataupdated[346972]?.value !== 'POC'}
										/>
										POC
									</div>
								</div>

								<div
									className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype}`}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width15_2} ${sdWanStyle.pad_7t}`}
									>
										Billing in
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346973]?.value === 'Advance'}
											disabled={
												sdWanCafDataupdated[346973]?.value !== 'Advance'
											}
										/>
										Advance
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width20} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346973]?.value === 'Arrears'}
											disabled={
												sdWanCafDataupdated[346973]?.value !== 'Arrears'
											}
										/>
										Arrears
									</div>
								</div>

								<div
									className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype}`}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width15_2} ${sdWanStyle.pad_7t}`}
									>
										Billing Periodicity
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width15} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={sdWanCafDataupdated[346974]?.value === 'Monthly'}
											disabled={
												sdWanCafDataupdated[346974]?.value !== 'Monthly'
											}
										/>
										Monthly
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width20} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346974]?.value === 'Quarterly'
											}
											disabled={
												sdWanCafDataupdated[346974]?.value !== 'Quarterly'
											}
										/>
										Quarterly
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width20} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346974]?.value === 'Half Yearly'
											}
											disabled={
												sdWanCafDataupdated[346974]?.value !== 'Half Yearly'
											}
										/>
										Half Yearly
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										<input
											className={sdWanStyle.form_col}
											type='checkbox'
											name=''
											value=''
											checked={
												sdWanCafDataupdated[346974]?.value === 'Annually'
											}
											disabled={
												sdWanCafDataupdated[346974]?.value !== 'Annually'
											}
										/>
										Annually
									</div>
								</div>

								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width15_2} ${sdWanStyle.pad_7t}`}
									>
										Contract Period (Months)
									</div>
									{/* <div className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}>
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width15} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[346975]?.value}
										/>
									</div> */}
									<div className={sdWanStyle.dmy}>
										<input
											className={sdWanStyle.form_col}
											type='text'
											name=''
											value={sdWanCafDataupdated[346975]?.value}
											style={{ width: '40px', borderWidth: '1px' }}
										/>
									</div>
								</div>

								<div className={sdWanStyle.pad_t5}>
									<p className={sdWanStyle.ligthbg}> YEAR ON YEAR DISCOUNT </p>
								</div>

								<div
									className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype} ${sdWanStyle.pad_t5}`}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.wid50}`}>
										<div className={`${sdWancmnStyle.wifitable} `}>
											<table
												width='90%'
												border='0'
												cellspacing='0'
												cellpadding='0'
											>
												<tbody>
													<tr>
														<td>Year</td>
														<td>Discount (%)</td>
														<td>Penalty</td>
													</tr>
													<tr>
														<td>
															{sdWanCafDataupdated[346977]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346978]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346979]?.value || '\u00A0'}
														</td>
													</tr>
													<tr>
														<td>
															{sdWanCafDataupdated[346980]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346981]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346982]?.value || '\u00A0'}
														</td>
													</tr>
													<tr>
														<td>
															{sdWanCafDataupdated[346983]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346984]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346985]?.value || '\u00A0'}
														</td>
													</tr>
													<tr>
														<td>
															{sdWanCafDataupdated[346986]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346987]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346988]?.value || '\u00A0'}
														</td>
													</tr>
													<tr>
														<td>
															{sdWanCafDataupdated[346989]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346990]?.value || '\u00A0'}
														</td>
														<td>
															{sdWanCafDataupdated[346991]?.value || '\u00A0'}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>

								<div className={sdWanStyle.pad_t5}>
									<p className={sdWanStyle.ligthbg}> WAN SERVICES</p>
								</div>

								<div className={`${sdWancmnStyle.wifitable} `}>
									<table
										width='100%'
										border='0'
										cellspacing='0'
										cellpadding='0'
									>
										<tbody>
											<tr>
												<td>Sr No</td>
												<td>
													Underlay <br />
													(WAN1, WAN2, WAN3,..)
												</td>
												<td>
													WAN Type <br />
													(MPLS, ILL, BB, 4G,..)
												</td>
												<td>
													Provided by
													<br />
													(VIL/Customer){' '}
												</td>
												<td>
													If VIL Provided <br />
													(New/Existing)
												</td>
												<td>
													If VIL Existing,
													<br />
													Circuit ID
												</td>
												<td>
													If VIL New, <br />
													OSM ID/ <br />
													Feasibility ID
												</td>
												<td>
													If Customer provided,
													<br />
													ISP Name
												</td>
												<td>
													If Customer <br />
													Provided, Circuit ID
												</td>
											</tr>
											<tr>
												<td>{sdWanCafDataupdated[346993].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[346994].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[346995].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[346996].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[346997].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[346998].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347000].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347001].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[346999].value || '\u00A0'}</td>
											</tr>
											<tr>
												<td>{sdWanCafDataupdated[347002].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347003].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347004].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347005].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347006].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347007].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347009].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347010].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347008].value || '\u00A0'}</td>
											</tr>
											<tr>
												<td>{sdWanCafDataupdated[347011].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347012].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347013].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347014].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347015].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347016].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347018].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347019].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347017].value || '\u00A0'}</td>
											</tr>
											<tr>
												<td>{sdWanCafDataupdated[347020].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347021].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347022].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347023].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347024].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347025].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347027].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347028].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347026].value || '\u00A0'}</td>
											</tr>
											<tr>
												<td>{sdWanCafDataupdated[347029].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347030].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347031].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347032].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347033].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347034].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347036].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347037].value || '\u00A0'}</td>
												<td>{sdWanCafDataupdated[347035].value || '\u00A0'}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className={sdWanStyle.pad_t10}>
									<div
										className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid}`}
									>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}
										>
											SD-WAN Aggregated Site Bandwidth (Mbps/Gbps)
											<input
												className={` ${sdWanStyle.form_col} ${sdWanStyle.width20}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347038]?.value}
											/>
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}
										>
											<div
												className={` ${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype} ${sdWanStyle.pad_5t}`}
											>
												<div
													className={`${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.pad_t3} `}
												>
													Deployment Model
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347039]?.value ===
															'On Premise'
														}
														disabled={
															sdWanCafDataupdated[347039]?.value !==
															'On Premise'
														}
													/>
													On Premise
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347039]?.value ===
															'Cloud Based'
														}
														disabled={
															sdWanCafDataupdated[347039]?.value !==
															'Cloud Based'
														}
													/>
													Cloud Based
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className={sdWanStyle.pad_t5}>
									<p className={sdWanStyle.ligthbg}> VIL CPE DETAILS</p>
								</div>
								<div className={`${sdWancmnStyle.wifitable} `}>
									<table
										width='100%'
										border='0'
										cellspacing='0'
										cellpadding='0'
									>
										<tbody>
											<tr>
												<td>Make</td>
												<td>
													CPE Form factor <br />
													(Physical/Virtual)
												</td>
												<td>Model</td>
												<td>
													Hardware Quantity <br />
													(Original/Modify/Final)
												</td>
											</tr>
											<tr>
												<td>
													{sdWanCafDataupdated[347040]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347041]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347042]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347043]?.value || '\u00A0'}
												</td>
											</tr>
											<tr>
												<td>
													{sdWanCafDataupdated[347044]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347045]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347046]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347047]?.value || '\u00A0'}
												</td>
											</tr>
											<tr>
												<td>
													{sdWanCafDataupdated[347048]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347049]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347050]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347051]?.value || '\u00A0'}
												</td>
											</tr>
											<tr>
												<td>
													{sdWanCafDataupdated[347052]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347053]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347054]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347055]?.value || '\u00A0'}
												</td>
											</tr>
											<tr>
												<td>
													{sdWanCafDataupdated[347056]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347057]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347058]?.value || '\u00A0'}
												</td>
												<td>
													{sdWanCafDataupdated[347059]?.value || '\u00A0'}
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className={sdWanStyle.pad_t10}>
									<div
										className={` ${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype} ${sdWanStyle.pad_5t}`}
									>
										<div
											className={` ${sdWanStyle.fleft} ${sdWancmnStyle.width30} `}
										>
											<div
												className={` ${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype} ${sdWanStyle.pad_5t}`}
											>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.pad_t3} `}
												>
													<strong>CPE Provided By</strong>
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347060]?.value === 'VIL'
														}
														disabled={
															sdWanCafDataupdated[347060]?.value !== 'VIL'
														}
													/>
													VIL
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347060]?.value === 'Customer'
														}
														disabled={
															sdWanCafDataupdated[347060]?.value !== 'Customer'
														}
													/>
													Customer
												</div>
											</div>
										</div>

										<div
											className={` ${sdWanStyle.fleft} ${sdWancmnStyle.width36} `}
										>
											<div
												className={` ${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype} ${sdWanStyle.pad_5t}`}
											>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.pad_t3} `}
												>
													<strong>CPE Managed By</strong>
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347061]?.value === 'VIL'
														}
														disabled={
															sdWanCafDataupdated[347061]?.value !== 'VIL'
														}
													/>
													VIL
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347061]?.value === 'Partner'
														}
														disabled={
															sdWanCafDataupdated[347061]?.value !== 'Partner'
														}
													/>
													Partner
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347061]?.value === 'Customer'
														}
														disabled={
															sdWanCafDataupdated[347061]?.value !== 'Customer'
														}
													/>
													Customer
												</div>
											</div>
										</div>

										<div
											className={` ${sdWanStyle.fleft} ${sdWancmnStyle.width30} `}
										>
											<div
												className={` ${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype} ${sdWanStyle.pad_5t}`}
											>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.pad_t3} `}
												>
													<strong>CPE as Standalone</strong>
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347062]?.value === 'Yes'
														}
														disabled={
															sdWanCafDataupdated[347062]?.value !== 'Yes'
														}
													/>
													Yes
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347062]?.value === 'No'
														}
														disabled={
															sdWanCafDataupdated[347062]?.value !== 'No'
														}
													/>
													No
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class=''>
									<div
										className={` ${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype} ${sdWanStyle.pad_5t}`}
									>
										<div
											className={` ${sdWanStyle.fleft} ${sdWancmnStyle.width33} `}
										>
											<div
												className={` ${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype} ${sdWanStyle.pad_5t}`}
											>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.pad_t3} `}
												>
													<strong>CPE Delivery</strong>
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347063]?.value === 'Single'
														}
														disabled={
															sdWanCafDataupdated[347063]?.value !== 'Single'
														}
													/>
													Single
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347063]?.value === 'Dual'
														}
														disabled={
															sdWanCafDataupdated[347063]?.value !== 'Dual'
														}
													/>
													Dual
												</div>
											</div>
										</div>

										<div
											className={` ${sdWanStyle.fleft} ${sdWancmnStyle.width36} `}
										>
											<div
												className={` ${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.custype} ${sdWanStyle.pad_5t}`}
											>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.pad_t3} `}
												>
													<strong>CPE Resiliency</strong>
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347064]?.value ===
															'Active/Active'
														}
														disabled={
															sdWanCafDataupdated[347064]?.value !==
															'Active/Active'
														}
													/>
													Active/Active
												</div>
												<div
													className={` ${sdWanStyle.fleft} ${sdWanStyle.pad_r5} ${sdWanStyle.check} ${sdWanStyle.text_r} `}
												>
													<input
														className={sdWanStyle.form_col}
														type='checkbox'
														name=''
														value=''
														checked={
															sdWanCafDataupdated[347064]?.value ===
															'Active/Passive'
														}
														disabled={
															sdWanCafDataupdated[347064]?.value !==
															'Active/Passive'
														}
													/>
													Active/Passive
												</div>
											</div>
										</div>
									</div>
								</div>

								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<div className={sdWanStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>

					<page size='A4'>
						<div className={sdWanStyle.section2}>
							<div className={sdWanStyle.font8}>
								<div className={sdWanStyle.pad_t5}>
									<p className={sdWanStyle.ligthbg}>CHARGE DESCRIPTION</p>
								</div>
								<div className={sdWanStyle.char} style={{ marginTop: '18px' }}>
									<div
										className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.pad_t5}`}
									>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width170px} ${sdWanStyle.pad_t3}`}
										>
											Specify Currency
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width5} ${sdWanStyle.pad_r5}`}
										>
											<input
												className={sdWanStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={sdWanCafDataupdated[347066]?.value === 'INR'}
												disabled={sdWanCafDataupdated[347066]?.value !== 'INR'}
											/>
											INR
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width5} ${sdWanStyle.pad_r5}`}
										>
											<input
												className={sdWanStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked={sdWanCafDataupdated[347066]?.value === 'USD'}
												disabled={sdWanCafDataupdated[347066]?.value !== 'USD'}
											/>
											USD
										</div>

										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width15} ${sdWanStyle.textcenter}`}
										>
											One Time (A)
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width15} ${sdWanStyle.textcenter}`}
										>
											Annual Recurring (B)
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width15} ${sdWanStyle.textcenter}`}
										>
											Security Deposit (C)
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width15} ${sdWanStyle.textcenter}`}
										>
											Grand Total (A+B+C)
										</div>
									</div>

									<div
										className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.pd_t3}`}
									>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.w250} ${sdWanStyle.pd_t3}`}
										>
											Managed Service Charges
										</div>

										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347067]?.value}
											/>
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347068]?.value}
											/>
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347069]?.value}
											/>
										</div>

										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347070]?.value}
											/>
										</div>
									</div>

									<div
										className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.pd_t3}`}
									>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.w250} ${sdWanStyle.pd_t3}`}
										>
											Hardware Charges
										</div>

										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347071]?.value}
											/>
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347072]?.value}
											/>
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347073]?.value}
											/>
										</div>

										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347074]?.value}
											/>
										</div>
									</div>

									<div
										className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.pd_t3}`}
									>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.w250} ${sdWanStyle.pd_t3}`}
										>
											Controller Charges
										</div>

										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347075]?.value}
											/>
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347076]?.value}
											/>
										</div>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347077]?.value}
											/>
										</div>

										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width18} ${sdWanStyle.textcenter}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width85}`}
												type='text'
												name=''
												value={sdWanCafDataupdated[347078]?.value}
											/>
										</div>
									</div>

									<div
										className={`${sdWanStyle.check} ${sdWanStyle.over_hid} ${sdWanStyle.pad_t10}`}
									>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.w250} ${sdWanStyle.pd_t3}`}
										>
											<strong>Total Order Value</strong>
										</div>

										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.wfull} ${sdWanStyle.pad_l10}`}
										>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width91} `}
												type='text'
												name=''
												value={sdWanCafDataupdated[347079]?.value}
											/>
										</div>
									</div>
								</div>
								<div
									className={` ${sdWanStyle.over_hid} ${sdWanStyle.referbo}`}
								>
									<strong>Comments:</strong>
									<textarea
										className={`${sdWanStyle.form_col} ${sdWanStyle.inputext_brd_list} ${sdWanStyle.areatext} `}
										placeholder='Comments:'
									>
										{sdWanCafDataupdated[347080]?.value}
									</textarea>
								</div>

								<p className={sdWanStyle.ligthbg}>DECLARATION :</p>
								<div className={sdWanStyle.padb20}>
									<p class=''>
										I / We confirm having received, read and understood the
										Product Terms & Conditions (provided overleaf) and the
										General Terms & Conditions. I / We further confirm that the
										tariff plan selected and applicable rates form part of this
										Agreement (as defined herein) and I / We agree to abide by
										the Applicable Law in force and also any statutory
										amendments, or new legistlations as may be enacted from time
										to time, in so far as they realte to the services. I / We
										hereby declare and confirm that the above information
										provided by us is true and correct in all respects and I /
										We hereby undertake to be bound by the same.
									</p>
								</div>
								<p className={sdWanStyle.ligthbg}>IMPORTANT</p>
								<p class='' style={{ marginTop: '18px' }}>
									<strong>Customer Responsibility</strong>
								</p>
								<p class=''>
									Customer would be required to provide following for site
									readiness
								</p>

								<div class=' '>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Lockable conditioned space for housing SDWAN equipments
									</div>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width10} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[347087]?.value}
									/>
								</div>
								<div className={sdWanStyle.pad_t10}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Power
									</div>
									<input
										className={` ${sdWanStyle.width10} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[347088]?.value}
									/>
								</div>
								<div className={sdWanStyle.pad_t10}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Earthing
									</div>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width10} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[347089]?.value}
									/>
								</div>
								<p>
									Customer would be required to grant following permission to
									VIL & its partner
								</p>

								<div className={sdWanStyle.pad_t10}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Right of way permission
									</div>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width10} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[347091]?.value}
									/>
								</div>

								<div className={sdWanStyle.pad_t10}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Roof top space for pole / tower installation
									</div>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width10} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[347107]?.value}
									/>
								</div>

								<div className={sdWanStyle.pad_t10}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Ground space for Pole / tower installation
									</div>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width10} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[347108]?.value}
									/>
								</div>
								<div
									className={`${sdWanStyle.pad_t10} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Site access to employees / authorized representatives of VIL
										/ its affiliates in the execution of their duty for the
										purpose of installation and/or configuration of equipment
									</div>
									<input
										className={`${sdWanStyle.form_col} ${sdWanStyle.width10} `}
										type='text'
										name=''
										value={sdWanCafDataupdated[347109]?.value}
									/>
								</div>
								<div className={sdWanStyle.brbtm1}>
									<p>
										<strong>Deemed Acceptance</strong> <br />
										Customer is expected to provide acceptance of the SDWAN
										services in 5 business days post testing after which VIL
										will start billing the customer for the SDWAN services. In
										absence of any shortcomings on the SDWAN services
										performance or acceptance communication from the customer
										within 5 days, VIL will deem the SDWAN services as accepted
										by the customer
									</p>
								</div>
								<div className={sdWanStyle.over_hid}>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width65} ${sdWanStyle.pad_t10} `}
									>
										<p
											className={` ${sdWanStyle.margin0} ${sdWanStyle.pad_5t}`}
										>
											Applicant's Name / Authorised Signatory's Name
										</p>
										<p className={sdWanStyle.margin0}>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width98per} ${sdWanStyle.cmnt_input} `}
												type='text'
												name=''
												value={sdWanCafDataupdated[347112]?.value}
											/>
										</p>
										<div
											className={`${sdWanStyle.over_hid} ${sdWanStyle.pad_t15} `}
										>
											<div
												className={`${sdWanStyle.fleft} ${sdWanStyle.width30} `}
											>
												<p className={sdWanStyle.margin0}>Designation</p>
												<p className={sdWanStyle.margin0}>
													<input
														className={`${sdWanStyle.form_col} ${sdWanStyle.widthfull} ${sdWanStyle.cmnt_input} `}
														type='text'
														name=''
														value={sdWanCafDataupdated[347113]?.value}
													/>
												</p>
											</div>
											<div
												className={`${sdWanStyle.fleft} ${sdWanStyle.width30} ${sdWanStyle.mar_l20} `}
											>
												<p className={sdWanStyle.margin0}>Place</p>
												<p className={sdWanStyle.margin0}>
													<input
														className={`${sdWanStyle.form_col} ${sdWanStyle.widthfull} ${sdWanStyle.cmnt_input} `}
														type='text'
														name=''
														value={sdWanCafDataupdated[347114]?.value}
													/>
												</p>
											</div>
											<div
												className={`${sdWanStyle.fleft} ${sdWanStyle.width30} ${sdWanStyle.mar_l20} `}
											>
												<p className={sdWanStyle.margin0}>Date and Time</p>
												<p className={sdWanStyle.margin0}>
													<input
														className={`${sdWanStyle.form_col} ${sdWanStyle.width96per} ${sdWanStyle.cmnt_input} `}
														type='text'
														name=''
														value={sdWanCafDataupdated[347115]?.value}
													/>
												</p>
											</div>
										</div>
										<p
											className={` ${sdWanStyle.margin0} ${sdWanStyle.pad_5t}`}
										>
											Account Manager Name
										</p>
										<p className={sdWanStyle.margin0}>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width98per} ${sdWanStyle.cmnt_input} `}
												type='text'
												name=''
												value={sdWanCafDataupdated[347116]?.value}
											/>
										</p>
										<p
											className={`${sdWanStyle.margin0} ${sdWanStyle.pad_20t} `}
										>
											Account Manager Circle Office
										</p>
										<p className={sdWanStyle.margin0}>
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.wid120p} ${sdWanStyle.cmnt_input} `}
												type='text'
												name=''
												value={sdWanCafDataupdated[347117]?.value}
											/>
										</p>
										<div
											className={`${sdWanStyle.over_hid} ${sdWanStyle.pad_20t} `}
										>
											<div
												className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}
											>
												<p className={sdWanStyle.margin0}>
													Channel Partner Name
												</p>
												<p className={sdWanStyle.margin0}>
													<input
														className={`${sdWanStyle.form_col} ${sdWanStyle.widthfull} ${sdWanStyle.cmnt_input} `}
														type='text'
														name=''
														value={sdWanCafDataupdated[347118]?.value}
													/>
												</p>
											</div>
											<div
												className={`${sdWanStyle.flright} ${sdWanStyle.width30} `}
											>
												<p className={sdWanStyle.margin0}>
													Channel Partner Code
												</p>
												<p className={sdWanStyle.margin0}>
													<input
														className={`${sdWanStyle.form_col} ${sdWanStyle.width92per} ${sdWanStyle.cmnt_input} `}
														type='text'
														name=''
														value={sdWanCafDataupdated[347119]?.value}
													/>
												</p>
											</div>
										</div>
									</div>
									<div
										className={`${sdWanStyle.flright} ${sdWanStyle.width30} `}
									>
										<div className={sdWanStyle.signate}>
											{firstCaps !== '' ? (
												<div className={Signcss}>
													{firstCaps + '  .  '}
													<span className='under_line'>{secondBold}</span>
												</div>
											) : (
												''
											)}
											Signature of the Customer / Authorised signatory within
											this box only (In case of institutional, please affix
											office/company seal)
										</div>
										<div className={sdWanStyle.signate_account}>
											{acctMngSignImageData !== null &&
											acctMngSignImageData !== '' ? (
												<div
													className='signate_img input_sign'
													style={{ marginBottom: '13px' }}
												>
													<img
														src={
															acctMngSignImageData !== null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(acctMngSignImageData)
																: ''
														}
														alt={'Account Manager Sign'}
													/>
												</div>
											) : (
												''
											)}
											Signature of the Account Manager
										</div>
									</div>
								</div>

								{/* <div className={sdWanStyle.pad_5t}>
									<div
										className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
									>
										<div
											className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}
										>
											Name
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
												type='text'
												name=''
												value=''
											/>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</page>
					<page>
						<div className={sdWanStyle.section2}>
							<div className={sdWanStyle.font8}>
								<p className={sdWanStyle.margin0}>
									<strong>Customer declaration:</strong>
								</p>

								<div className={sdWanStyle.over_hid}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width30} `}>
										<div className={sdWanStyle.signate_account}>
											{offiStampCustImageData !== null &&
											offiStampCustImageData !== '' ? (
												<div
													className='signate_img input_sign'
													style={{
														marginBottom: '13px',
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													<img
														src={
															offiStampCustImageData !== null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(offiStampCustImageData)
																: ''
														}
														alt={'Official of Stamp of Customer Sign'}
													/>
												</div>
											) : (
												''
											)}
											Official of Stamp of Customer
										</div>
									</div>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width30} ${sdWanStyle.padl5per} `}
									>
										<div className={sdWanStyle.signate_account}>
											{signateImageData !== null && signateImageData !== '' ? (
												<div
													className='signate_img input_sign'
													style={{ marginBottom: '13px' }}
												>
													<img
														src={
															signateImageData !== null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(signateImageData)
																: ''
														}
														alt={'Signatures'}
													/>
												</div>
											) : (
												''
											)}
											Signatures
										</div>
									</div>
									<div
										className={`${sdWanStyle.flright} ${sdWanStyle.width30} `}
									>
										<div className={sdWanStyle.signate_account}>
											{desigAuthSignImageData !== null &&
											desigAuthSignImageData !== '' ? (
												<div
													className='signate_img input_sign'
													style={{ marginBottom: '13px' }}
												>
													<img
														src={
															desigAuthSignImageData !== null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(desigAuthSignImageData)
																: ''
														}
														alt={'Designation Authorised Sign'}
													/>
												</div>
											) : (
												''
											)}
											Designation Authorised Sign
										</div>
									</div>
								</div>
								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width20} `}
										style={{ marginTop: '15px' }}
									>
										Date
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347126]?.value}
										/>
									</div>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}
										style={{ marginTop: '15px' }}
									>
										Place
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347127]?.value}
										/>
									</div>
								</div>

								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Name
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347128]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Witness
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347129]?.value}
										/>
									</div>
								</div>

								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Name
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347130]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Address
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347131]?.value}
										/>
									</div>
								</div>
								<div className={sdWanStyle.over_hid}>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width30} `}>
										<div className={sdWanStyle.signate_account}>
											{vodafoneCustImageData !== null &&
											vodafoneCustImageData !== '' ? (
												<div
													className='signate_img input_sign'
													style={{ marginBottom: '13px' }}
												>
													<img
														src={
															vodafoneCustImageData !== null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(vodafoneCustImageData)
																: ''
														}
														alt={
															'Signature of Vodafone Idea Authorised Signatory'
														}
													/>
												</div>
											) : (
												''
											)}
											Signature of Vodafone Idea Authorised Signatory
										</div>
									</div>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width30} ${sdWanStyle.padl5per} `}
									>
										<div className={sdWanStyle.signate_account}>
											{signateVodaImageData !== null &&
											signateVodaImageData !== '' ? (
												<div
													className='signate_img input_sign'
													style={{ marginBottom: '13px' }}
												>
													<img
														src={
															signateVodaImageData !== null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(signateVodaImageData)
																: ''
														}
														alt={'Signatures'}
													/>
												</div>
											) : (
												''
											)}
											Signatures
										</div>
									</div>
									<div
										className={`${sdWanStyle.flright} ${sdWanStyle.width30} `}
									>
										<div className={sdWanStyle.signate_account}>
											{desigAuthVodaSignImageData !== null &&
											desigAuthVodaSignImageData !== '' ? (
												<div
													className='signate_img input_sign'
													style={{ marginBottom: '13px' }}
												>
													<img
														src={
															desigAuthVodaSignImageData !== null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(desigAuthVodaSignImageData)
																: ''
														}
														alt={'Vodafone Idea Stamp'}
													/>
												</div>
											) : (
												''
											)}
											Designation Authorised Sign
										</div>
									</div>
								</div>
								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width20} `}
										style={{ marginTop: '15px' }}
									>
										Date
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347136]?.value}
										/>
									</div>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width25} `}
										style={{ marginTop: '15px' }}
									>
										Place
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width65} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347137]?.value}
										/>
									</div>
								</div>

								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Name
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347138]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Witness
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347139]?.value}
										/>
									</div>
								</div>

								<div
									className={`${sdWanStyle.auth} ${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Name
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347140]?.value}
										/>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width50} `}>
										Address
										<input
											className={`${sdWanStyle.form_col} ${sdWanStyle.width80} `}
											type='text'
											name=''
											value={sdWanCafDataupdated[347141]?.value}
										/>
									</div>
								</div>
								<div
									className={`${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div
										className={`${sdWanStyle.fleft} ${sdWanStyle.width50}`}
										style={{ marginTop: '15px' }}
									>
										P. O. Date (Accepts today and past date)
										<div className={sdWanStyle.dmy}>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347142]?.value
													.substring(8, 10)
													.charAt(0)}
												placeholder='D'
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347142]?.value
													.substring(8, 10)
													.charAt(1)}
												placeholder='D'
											/>
											-
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347142]?.value
													.substring(5, 7)
													.charAt(0)}
												placeholder='M'
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347142]?.value
													.substring(5, 7)
													.charAt(1)}
												placeholder='M'
											/>
											-
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347142]?.value
													.substring(0, 4)
													.charAt(2)}
												placeholder='Y'
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347142]?.value
													.substring(0, 4)
													.charAt(3)}
												placeholder='Y'
											/>
										</div>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.width40} `}>
										<div
											className={sdWanStyle.auth}
											style={{ marginTop: '15px' }}
										>
											P.O. Number
											<input
												className={`${sdWanStyle.form_col} ${sdWanStyle.width50} `}
												type='text'
												name=''
												value={sdWanCafDataupdated[347143]?.value}
											/>
										</div>
									</div>
								</div>

								<div
									className={`${sdWanStyle.margin0} ${sdWanStyle.over_hid} `}
								>
									<div className={sdWanStyle.fleft}>
										P.O. Start Date
										<div className={sdWanStyle.dmy}>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347144]?.value
													.substring(8, 10)
													.charAt(0)}
												placeholder='D'
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347144]?.value
													.substring(8, 10)
													.charAt(1)}
												placeholder='D'
											/>
											-
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347144]?.value
													.substring(5, 7)
													.charAt(0)}
												placeholder='M'
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347144]?.value
													.substring(5, 7)
													.charAt(1)}
												placeholder='M'
											/>
											-
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347144]?.value
													.substring(0, 4)
													.charAt(2)}
												placeholder='Y'
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347144]?.value
													.substring(0, 4)
													.charAt(3)}
												placeholder='Y'
											/>
										</div>
									</div>
									<div className={`${sdWanStyle.fleft} ${sdWanStyle.padl30} `}>
										P.O.Expiry Date
										<div className={sdWanStyle.dmy}>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347145]?.value
													.substring(8, 10)
													.charAt(0)}
												placeholder='D'
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347145]?.value
													.substring(8, 10)
													.charAt(1)}
												placeholder='D'
											/>
											-
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347145]?.value
													.substring(5, 7)
													.charAt(0)}
												placeholder='M'
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347145]?.value
													.substring(5, 7)
													.charAt(1)}
												placeholder='M'
											/>
											-
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347145]?.value
													.substring(0, 4)
													.charAt(2)}
												placeholder='Y'
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347145]?.value
													.substring(0, 4)
													.charAt(3)}
												placeholder='Y'
											/>
										</div>
									</div>

									<div className={`${sdWanStyle.fleft} ${sdWanStyle.padl30} `}>
										P.O. Term (in Months)
										<div className={sdWanStyle.dmy}>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347146]?.value
													.substring(0, 2)
													.charAt(0)}
											/>
											<input
												className={sdWanStyle.form_col}
												type='text'
												name=''
												value={sdWanCafDataupdated[347146]?.value
													.substring(0, 2)
													.charAt(1)}
											/>
										</div>
									</div>
									<div className={sdWanStyle.signateNew}>
										{firstCaps !== '' ? (
											<div style={{ padding: '0px' }} className={Signcss}>
												{firstCaps + '  .  '}
												<span className='under_line'>{secondBold}</span>
											</div>
										) : (
											''
										)}
										{imageData !== null && imageData !== '' ? (
											<div style={{ padding: '0px' }} className='signate_img'>
												<img
													src={
														imageData !== null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  encode(imageData)
															: ''
													}
													alt={'Company Seal'}
												/>
											</div>
										) : (
											''
										)}
									</div>
								</div>
							</div>
						</div>
					</page>
					<SdwanTermsCondtions
						isCrf={true}
						firstCaps={firstCaps}
						Signcss={Signcss}
						secondBold={secondBold}
						imageData={imageData}
						imageType={imageType}
					/>
					{excelJson !== null ? (
						<page size='A4'>
							<div>
								<div className='pad_t10 section2'>
									<p className='customfom'>Annexure to SDWAN CAF :- </p>
								</div>
								<JsonToTable json={excelJson} />
								<div>
									<p class='font10 ' style={{ marginTop: '20px' }}>
										<strong>Note:</strong> Address proofs will be required for
										each of the sites listed above as part of standard
										documentation guidelines; Master CAF referred here should be
										signed by the same
									</p>
								</div>

								<div class='fleft width50 pad_t10'>
									<p class='marb5'>Account Manager Name</p>
									<p class='margin0'>
										<input
											class='form_col widthfull inputext'
											type='text'
											name='Account Manager Name'
											readonly=''
											value={sdWanCafDataupdated[347116].value}
										/>
									</p>
									<div className='signate' style={{ position: 'relative' }}>
										<div className='signate_img input_sign'>
											{acctMngSignImageData !== null &&
											acctMngSignImageData !== '' ? (
												<img
													src={
														acctMngSignImageData !== null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  encode(acctMngSignImageData)
															: ''
													}
													alt={'Account Manager Sign'}
												/>
											) : (
												''
											)}
										</div>
										<div>Signature of the Account Manager </div>
									</div>
								</div>
								<div
									className='signate float_img'
									style={{ position: 'initial' }}
								>
									<div className='over_hid '>
										<div className='over_hid '>
											Authorised Signatory Name
											<input
												className='form_col inputext'
												type='text'
												name='Name_CD'
												value={sdWanCafDataupdated[347112].value}
												readOnly
											/>
										</div>
									</div>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div
											style={{
												padding: '10px',
												display: 'flex',
												justifyContent: 'center',
											}}
											className='signate_img'
										>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</page>
					) : (
						''
					)}
				</div>
			</div>
		)
	} else {
		coloCrfContentView = crfData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				CAF Form not submitted yet.
			</div>
		)
	}
	return <div>{coloCrfContentView}</div>
}

export default SdwanCafForm
